// @import '@radix-ui/colors/black-alpha.css';
// @import '@radix-ui/colors/violet.css';

$track-color: $primary;

.SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 200px;
    height: 20px;
}

.SliderTrack {
    background-color: $track-color;
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 3px;
}

.SliderRange {
    position: absolute;
    background-color: $track-color;
    border-radius: 9999px;
    height: 100%;
}

.SliderThumb {
    display: block;
    width: 20px;
    height: 20px;
    background-color: white;
    box-shadow: 0 2px 10px $primary;
    border-radius: 10px;
}

.SliderThumb:hover {
    background-color: $primary;
}

.SliderThumb:focus {
    outline: none;
    box-shadow: 0 0 0 5px $primary ;
}